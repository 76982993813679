import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { arrayMove } from "@dnd-kit/sortable"

import {
    returCategoriesHighWidget,
    returnCategoriesBrandsWidget,
    returnHighlightedProductsWidget,
    returnSliderWidget,
} from "./helpers"

// ----------------------------------------------------------------------------

function _HeroEditor(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const {
        className,
        values,
        onChange,
        onDelete,
        brandOptions,
        categoryOptions,
        onMove,
        index,
        draggableValueHeroFromChild
    } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------
    const [valueIsDraggingChild, setValueIsDraggingChild] = useState(false)
    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        values?.targetType && onChange("target")("")
    }, [values?.targetType])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleDelete() {
        onDelete && onDelete(values)
    }

    function addSlide() {
        const newWidgets = [getNewItem(), ...(values?.slides ?? [])]
        onChange("slides")(newWidgets)
    }

    function deleteSlide(index) {
        const newWidgets = values?.slides?.filter((w, i) => i !== index)

        onChange("slides")(newWidgets)
    }

    const onChangeSlide = (contentKey, field) => (value) => {
        const updatedContent = values.slides?.map((w) => {
            if (w.id === contentKey) {
                return {
                    ...w,
                    [field]: value,
                }
            } else return w
        })

        onChange("slides")(updatedContent)
    }

    // DRAG and DROP Libraries
    const onDragEnd = (event) => {
        const { active, over } = event
        if (active.id === over.id) {
            return
        }
        const oldIndex = values.slides.indexOf(
            values.slides.find((x) => x.id === active.id)
        )
        const newIndex = values.slides.indexOf(
            values.slides.find((x) => x.id === over.id)
        )
        arrayMove(values.slides, oldIndex, newIndex)
        onChange("slides")(arrayMove(values.slides, oldIndex, newIndex))
    }
    const informationIsDragging = (value) => {
        setValueIsDraggingChild(value)
    }
    if(valueIsDraggingChild) {
        draggableValueHeroFromChild(valueIsDraggingChild)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const destinationOptions = [
        { label: "Categoria", value: "category" },
        { label: "Brand", value: "brand" },
    ]

    const widgetOptions = [
        { label: "Slider", value: "slider" },
        { label: "Categoria/Brand in evidenza", value: "catBrandsProds" },
        { label: "Categorie in evidenza", value: "catHigh" },
        { label: "Prodotti in evidenza", value: "prodHigh" },
    ]

    function getWidget() {
        if (values?.subType === "slider")
            return returnSliderWidget({
                className,
                addSlide,
                deleteSlide,
                onChangeSlide,
                values,
                widgetOptions,
                onChange,
                handleDelete,
                handleWidgetMove: onMove,
                index,
                onDragEnd,
                brandOptions,
                informationIsDragging,
            })
        if (values?.subType === "catBrandsProds")
            return returnCategoriesBrandsWidget({
                className,
                destinationOptions,
                values,
                widgetOptions,
                onChange,
                handleDelete,
                brandOptions,
                categoryOptions,
                handleWidgetMove: onMove,
                index,
            })
        if (values?.subType === "catHigh")
            return returCategoriesHighWidget({
                addSlide,
                categoryOptions,
                className,
                deleteSlide,
                handleDelete,
                onChange,
                onChangeSlide,
                values,
                widgetOptions,
                handleWidgetMove: onMove,
                index,
            })
        if (values?.subType === "prodHigh") {
            return returnHighlightedProductsWidget({
                className,
                onChange,
                values,
                widgetOptions,
                handleDelete,
                addSlide,
                categoryOptions,
                deleteSlide,
                onChangeSlide,
                handleWidgetMove: onMove,
                index,
            })
        }
    }

    return getWidget()
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_HeroEditor.propTypes = {
    className: PropTypes.string.isRequired,
    values: PropTypes.object,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    draggableValueHeroFromChild: PropTypes.func,
}

_HeroEditor.defaultProps = {}

// ----------------------------------------------------------------------------

const HeroEditor = styled(_HeroEditor)`
    & {
        .delete-btn {
            margin-top: 40px;
            width: 100%;
            height: 48px;
            /* max-width: unset; */
        }

        .button-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;
            margin-top: 40px;

            button {
                height: 48px;
            }

            .delete-btn {
                margin-top: 0px;
            }

            .arrows {
                display: flex;
                flex-direction: row;
                gap: 15px;
                .arrow {
                    display: block;
                    height: 48px;
                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        .input {
            margin-bottom: 20px;
            max-width: 100%;
            min-width: unset;
        }
        .color-picker {
            width: 250px;
            margin-bottom: 20px;
            .tag {
                border: 1px solid black;
            }
        }
        .filter-selector {
            margin: 20px 0px;

            .filters {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                overflow-x: auto;
                margin-bottom: 10px;
                .filter {
                    width: 200px;
                    margin: 20px 0px;
                    padding-right: 25px;
                    min-width: 200px;

                    &:first-child {
                        width: 175px;
                        min-width: 175px;
                    }

                    &:not(:first-child) {
                        padding-left: 25px;
                    }

                    &:not(:last-child) {
                        border-right: 1px solid
                            ${({ theme }) => theme.colors.primary};
                    }

                    .filter-label {
                        display: block;
                        margin: 5px 0px;
                        font-weight: 500;
                    }

                    .select {
                        flex: 1;
                        min-width: unset;
                        margin-bottom: 20px;
                        &.first {
                            margin-bottom: 10px;
                        }
                    }
                    .delete-button {
                        width: 100%;
                        font-size: 14px;
                        font-weight: 400;
                        height: 30px;
                    }
                }
            }
        }

        .slider-slides {
            width: 100%;
            display: flex;
            overflow: auto;
            flex-direction: row;
            gap: 20px;
            padding-bottom: 20px;

            .slider-slide {
                max-width: 250px;
                min-width: 250px;

                &.slide-dndkit-move {
                    background-color: rgb(248, 250, 247);
                    padding: 10px;
                    transition: all 0.3s;
                }

                .slide-select {
                    max-width: 100%;
                    min-width: 250px;
                }

                .slide-delete {
                    width: 100%;
                    margin-top: 10px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg {
                        transition: all 200ms ease;
                    }

                    &:hover {
                        background-color: ${({ theme }) => theme.colors.error};
                        svg {
                            /* fill: white; */
                            stroke: white;
                        }
                    }
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------
const getNewItem = () => ({
    type: "slider",
    id: Math.random() * 10000000000000,
    title: "",
    targetType: null,
    target: null,
    image: "",
})

export default HeroEditor
