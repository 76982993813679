import { Modal, message } from "antd"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"

import { publishCMS } from "../../../../api/Local"
import { useStateValue } from "../../context/AppStateStore"
// import { publishCMS } from "../../../../api/Local"
// import { useStateValue } from "../../context/AppStateStore"
import AppButton from "../AppButton"
import Menu from "./Menu"

// ----------------------------------------------------------------------------

function _Layout(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const [{ token }] = useStateValue()
    const [submitting, setSubmitting] = useState(false)

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handlePublish() {
        setSubmitting(true)
        publishCMS(token)
            .then(() => message.success("Dati CMS pubblicati con successo"))
            .catch((e) => {
                // eslint-disable-next-line
                console.log(e)
                message.error("Errore durante la pubblicazione dei dati.")
            })
            .finally(() => setSubmitting(false))
    }

    function confirm() {
        Modal.confirm({
            onOk: handlePublish,
            title: "Pubblicazione CMS",
            content:
                "Questa azione sovrascriverà il contenuto correntemente disponibile nell'app mobile. Sei sicuro di voler proseguire?",
            okText: "Sì, pubblica il CMS",
            cancelText: "Annulla",
        })
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <Menu />
            <main className="main-content">
                <AppButton
                    htmlType="button"
                    uxType="primary"
                    className="sync-button"
                    loading={submitting}
                    i
                    onClick={confirm}
                >
                    Pubblica CMS
                </AppButton>
                {props.children}
            </main>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Layout.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.object,
}

_Layout.defaultProps = {}

// ----------------------------------------------------------------------------

const Layout = styled(_Layout)`
    & {
        height: 100vh;
        width: 100vw;
        overflow-x: hidden;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        .menu {
        }
        .main-content {
            flex: 1;
            padding: 20px;
            padding-top: 30px;
            overflow-y: auto;
            overflow-x: hidden;
            position: relative;

            .sync-button {
                align-self: flex-end;
                position: absolute;
                right: 30px;
            }

            .page-title {
                font-size: 24px;
                font-weight: 600;
                margin-bottom: 30px;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default Layout
