import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { LoadingOutlined } from "@ant-design/icons"

//------------------------------------------------------------------------------

function _AppButton(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { children, onClick, htmlType, uxType, loading, disabled } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function getThemeClassName() {
        switch (uxType) {
            case "warning": {
                return "warning"
            }
            case "success": {
                return "success"
            }

            case "secondary": {
                return "secondary"
            }

            case "link": {
                return "link"
            }

            case "delete": {
                return "delete"
            }

            case "primary": {
                return "primary"
            }

            default: {
                return "primary"
            }
        }
    }
    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <button
            className={`${props.className} ${getThemeClassName()}`}
            type={htmlType}
            onClick={onClick}
            disabled={disabled || loading}
        >
            {loading && <LoadingOutlined className="loader" />}
            {children}
        </button>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AppButton.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    type: PropTypes.string,
    uxType: PropTypes.string,
    htmlType: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
}

_AppButton.defaultProps = {
    onClick: () => {},
    htmlType: "submit",
    style: "default",
}

// ----------------------------------------------------------------------------

const AppButton = styled(_AppButton)`
    & {
        position: relative;

        padding: 0 24px;
        max-width: 300px;
        border: 1px solid ${(props) => props.theme.colors.primary};
        border-radius: 6px;
        background-color: ${(props) => props.theme.colors.primary};

        font-weight: 600;
        color: ${(props) => props.theme.colors.light} !important;

        cursor: pointer;
        outline: none;
        transition: all 0.2s ease-in-out;

        z-index: 1;

        * > {
            z-index: 3;
        }

        .loader {
            margin-right: 5px;
        }

        :hover {
            ::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                background-color: ${(props) =>
                    `${props.theme.colors.hoverActiveClearer}`};

                z-index: 2;
            }
        }
        :disabled {
            background-color: ${({ theme }) =>
                theme?.colors?.secondaryColor} !important;
            color: ${({ theme }) => theme?.colors?.textMuted} !important;
            cursor: unset;
        }
    }

    &.primary {
        border: none;
        outline: none;
        height: 50px;
        font-size: 16px;
        letter-spacing: 0.1em;
        background-color: ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.light} !important;

        :hover {
            ::before {
                background-color: ${(props) =>
                    props.theme.colors.hoverTransparency};
            }
        }
        :disabled {
            background-color: ${({ theme }) =>
                theme?.colors?.secondaryColor} !important;
            color: ${({ theme }) => theme?.colors?.errorColor} !important;
            cursor: unset;
        }
    }

    &.secondary {
        font-size: 16px;
        letter-spacing: 0.3px;
        border: 1px solid ${(props) => props.theme.colors.primary};
        background-color: ${(props) => props.theme.colors.light};
        color: ${(props) => props.theme.colors.primary} !important;
        font-weight: 500;

        :hover {
            ::before {
                background-color: ${(props) =>
                    props.theme.colors.hoverTransparency};
            }
        }
    }

    &.success {
        border: 1px solid ${(props) => props.theme.colors.successColor};
        background-color: transparent;
        color: ${(props) => props.theme.colors.successColor} !important;

        :hover {
        }
    }

    &.link {
        padding: 0 12px;
        border: none;
        background-color: transparent;
        color: ${(props) => props.theme.colors.primaryColor} !important;
        font-weight: 500;

        :hover {
        }
    }

    &.warning {
        border: 1px solid ${(props) => props.theme.colors.warningDarkColor};
        background-color: transparent;
        color: ${(props) => props.theme.colors.warningDarkColor} !important;

        :hover {
            border-color: ${(props) => props.theme.colors.warningLightColor};
            color: ${(props) =>
                props.theme.colors.warningLightColor} !important;
        }
    }

    &.delete {
        border: 2px solid ${(props) => props.theme.colors.error} !important;
        font-size: 16px;
        letter-spacing: 0.1em;
        color: ${(props) => props.theme.colors.error} !important;
        background-color: ${(props) => props.theme.colors.light};
    }
`
// ----------------------------------------------------------------------------

export default AppButton
