import PropTypes from "prop-types"
import styled from "styled-components"
import { FiTrash2 } from "react-icons/fi"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { DragOutlined } from "@ant-design/icons"

import { renderSliderNavigationSelectors } from "../HeroEditor/helpers"

import AppButton from "../../../../../_shared/components/AppButton"
import AppInput from "../../../../../_shared/components/FormItems/AppInput"
import StyledImageUploadInput from "../../../../../_shared/components/FormItems/ImageUploadInput"

function SortableItem(props) {
    const {
        className,
        onChangeSlide,
        slide,
        deleteSlide,
        index,
        brandOptions,
        onChange,
        values,
        draggingvalue,
    } = props

    const slideId = slide.id
    const slideTitle = slide?.title

    // useState active effects click icon-move

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: slideId })

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
        zIndex: isDragging? 1001 : 999
    }

    // send value IsDragging to component Father
    if(isDragging){
        draggingvalue(isDragging)
    }

    return (
        <div
            className={className}
            ref={setNodeRef}
            style={style}
            {...attributes}
            key={slideId}

        >
            <div
                className={
                    isDragging === true
                        ? "slider-slide slide-dndkit-move slide-box-shadow"
                        : "slider-slide slide-dndkit-move "
                }
            >
                <div className="widget-content">
                    <DragOutlined
                        {...listeners}
                        className="icon-move-pointer"
                    />
                    <AppInput
                        className="input"
                        value={slideTitle}
                        onChange={onChangeSlide(slideId, "title")}
                        label="Titolo"
                        // style= {{ backgroundColor: 'white'}}
                    />
                    <AppInput
                        className="input"
                        value={slide?.btnText}
                        onChange={onChangeSlide(slideId, "btnText")}
                        label="Testo bottone"
                    />
                    {renderSliderNavigationSelectors({
                        slide,
                        onChangeSlide,
                        brandOptions,
                        slides: values?.slides,
                        onChange,
                    })}
                    <StyledImageUploadInput
                        image={slide?.image}
                        onUpload={onChangeSlide(slideId, "image")}
                    />
                    <AppButton
                        className="slide-delete"
                        htmlType="button"
                        uxType="delete"
                        onClick={() => deleteSlide(index)}
                    >
                        <FiTrash2 />
                    </AppButton>
                </div>
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

SortableItem.propTypes = {
    className: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    values: PropTypes.object,
    slide: PropTypes.object,
    onChangeSlide: PropTypes.func,
    deleteSlide: PropTypes.func,
    brandOptions: PropTypes.func,
    onChangeItem: PropTypes.func,
    onChange: PropTypes.func,
    draggingvalue: PropTypes.func,
}

SortableItem.defaultProps = {}

const SortableItemStyle = styled(SortableItem)`
    & {
        .widget-content {
            .icon-move-pointer {
                font-size: 20px;
                padding-bottom: 10px;
                cursor: grab;
            }
        }
        .slide-box-shadow {
            // background-color: rgba(93, 144, 88, 0.4) !important;
            box-shadow: 14px 16px 14px 5px rgba(0, 0, 0, 0.1),
                0px 10px 15px -3px rgba(0, 0, 0, 0.1);
            transition: all 0.3s;
        }
    }
`

export default SortableItemStyle
