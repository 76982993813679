import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { getProducts } from "../../../../../api/magento"
import { useStateValue } from "../../../context/AppStateStore"
import AppSelect from "../AppSelect"

// ----------------------------------------------------------------------------

function _ProductSelector(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { onChange, cachedOptions } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [search, setSearch] = useState("")
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([])
    const timeoutRef = useRef(null)

    const [{ token }] = useStateValue()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        // bring initial options in scope
        const prevOptions = options
        if (cachedOptions) {
            setOptions(cachedOptions)
            setTimeout(() => {
                setOptions(prevOptions)
            }, 100)
        }
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (search.length < 3) {
            return
        }
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
        timeoutRef.current = setTimeout(() => {
            setLoading(true)
            getProducts(search, token)
                .then(({ items }) => {
                    setProducts(items ?? [])
                })
                .finally(() => {
                    setLoading(false)
                })
        }, 500)

        return () => {
            clearTimeout(timeoutRef.current)
        }
        //eslint-disable-next-line
    }, [search])

    useEffect(() => {
        if (!products || !products.length) {
            return
        }
        setOptions(
            products.map(({ sku, name }) => {
                return {
                    value: sku,
                    label: name,
                }
            })
        )
        //eslint-disable-next-line
    }, [products])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <AppSelect
            {...props}
            options={options}
            searchValue={search}
            onSearch={setSearch}
            onChange={(sku) => {
                const product = products.find((product) => product.sku === sku)
                onChange(product)
            }}
            filterOption={false}
            showSearch
            loading={loading}
        />
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_ProductSelector.propTypes = {
    className: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    cachedOptions: PropTypes.array,
}

_ProductSelector.defaultProps = {}

// ----------------------------------------------------------------------------

const ProductSelector = styled(_ProductSelector)`
    & {
    }
`
// ----------------------------------------------------------------------------

export default ProductSelector
