import { Spin } from "antd"
import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { useShops } from "./useShops"

// ----------------------------------------------------------------------------

function _CmsRoot(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [shops, loading] = useShops()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function getName(code) {
        const nameMap = {
            //it: "Italia",
            shop_it: "Shop Italia",
            uk_en: "Regno Unito (EN)",
            uk_shop_en: "Shop Regno Unito (EN)",

            eu_en: "Europa",
            london_en: "Londra",
            mondello: "Mondello",
            palermo_it: "Palermo",
        }
        return nameMap[code] || code
    }

    function renderShops() {
        return (
            <div className="shops">
                {shops.map((shop) => {
                    const { id, code } = shop

                    // Not Italia [code]
                    if (id !== 1) {
                        return (
                            <Link
                                className="store"
                                to={`/cms/${code}`}
                                key={id}
                            >
                                <span className="code">{code}</span>
                                <span to={`/cms/${code}`}>{getName(code)}</span>
                            </Link>
                        )
                    }
                })}
            </div>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <Spin className="spin" spinning={loading}>
            <div className={`${className}`}>
                <h1 className="page-title">CMS | Prezzemolo APP</h1>
                {renderShops()}
            </div>
        </Spin>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_CmsRoot.propTypes = {
    className: PropTypes.string.isRequired,
}

_CmsRoot.defaultProps = {}

// ----------------------------------------------------------------------------

const CmsRoot = styled(_CmsRoot)`
    & {
        min-height: 600px;
        .shops {
            .store {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                width: 100%;
                height: 50px;
                padding: 0px 20px;
                border: none;
                border-bottom: 1px solid #ccc;
                background: none;
                outline: none;
                transition: background-color 0.3s ease-in-out;
                cursor: pointer;

                text-align: left;

                &:first-child {
                    border-top: 1px solid #ccc;
                }

                &:hover {
                    background-color: #ececec;
                }

                > span {
                    color: #000;
                }

                .code {
                    font-size: 11px;
                    color: #a8a8a8;
                    margin-bottom: 3px;
                    display: block;
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default CmsRoot
