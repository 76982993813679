import { Modal, message } from "antd"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import Collapsible from "react-collapsible"
import { IoIosArrowDown, IoIosInformationCircleOutline } from "react-icons/io"
import { useParams } from "react-router-dom"
import styled from "styled-components"

import { updateCmsSection, uploadFile } from "../../../../../api/Local"
import AppButton from "../../../../_shared/components/AppButton"
import { useStateValue } from "../../../../_shared/context/AppStateStore"
import useCategories from "../../../../_shared/hooks/useCategories"
import useForm from "../../../../_shared/hooks/useForm"
import useRestResource from "../../../../_shared/hooks/useRestResource"
import HeroEditor from "./HeroEditor"

// ----------------------------------------------------------------------------

function _Home(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props
    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const params = useParams() // available on mount
    const [{ token }] = useStateValue()
    const [homeContent] = useRestResource("/cms", params.slug, token)
    const rootCategory = useCategories()
    const [brands] = useRestResource("/magento", "brands", token)

    const [form, setOne, setMany] = useForm()
    const [openWidget, setOpenwidget] = useState({})
    const [valueIsDraggingChild, setValueIsDraggingChild] = useState(false)

    const [submitting, setSubmitting] = useState(false)

    const categoryOptions = useMemo(() => {
        const options = []

        function discover(category, prefix = "") {
            if (prefix) {
                options.push({
                    label: `${prefix} > ${category.name}`,
                    value: category.id,
                })
            } else {
                options.push({
                    label: category.name,
                    value: category.id,
                })
            }
            prefix = prefix ? `${prefix} > ${category.name}` : category.name

            if (category.children_data) {
                category.children_data.forEach((child) =>
                    discover(child, prefix)
                )
            }
        }

        if (rootCategory && rootCategory.children_data) {
            rootCategory.children_data.forEach((category) => discover(category))
        }
        return options
    }, [rootCategory])

    const brandOptions = useMemo(() => {
        return (
            brands
                ?.filter?.((brand) => !!brand.value)
                ?.map((brand) => ({
                    label: brand.label,
                    value: brand.value,
                })) ?? []
        )
    }, [brands])

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        homeContent &&
            setMany({
                ...homeContent,
            })
    }, [homeContent])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    const updateForm = (widgetKey) => (field) => (value) => {
        const updatedWidgets = form?.widgets?.map((w) => {
            if (w.id === widgetKey) {
                return {
                    ...w,
                    [field]: value,
                }
            } else return w
        })

        setOne("widgets", updatedWidgets)
    }

    function getWidget(key) {
        return form?.widgets?.find((w) => w.id === key)
    }

    function handleAddWidget() {
        const newWidgets = [getNewWidget(), ...(form?.widgets ?? [])]
        setOne("widgets", newWidgets)
    }

    function handleReset() {
        function execute() {
            // setOpenwidget(null)
            setMany(homeContent)
        }
        Modal.confirm({
            onOk: execute,
            title: "Conferma azione",
            content:
                "Stai per resettare le modifiche effettuate ai loro valori precedentemente salvati.",
        })
    }

    async function handleImageUpload(values) {
        // console.log("values", values)
        if (values?.widgets) {
            for (const widget of values?.widgets ?? []) {
                if (
                    widget.subType === "slider" ||
                    widget.subType === "catHigh"
                ) {
                    for (const slide of widget.slides) {
                        if (slide.image) {
                            if (!(typeof slide.image === "string")) {
                                const newImage = await uploadFile(
                                    slide.image,
                                    token
                                )
                                slide.image = newImage?.url
                                slide.imageId = newImage?.id
                                slide.thumbnail = newImage?.thumbnail
                            }
                        }
                    }
                } else {
                    if (widget.image) {
                        if (!(typeof widget.image === "string")) {
                            const newImage = await uploadFile(
                                widget.image,
                                token
                            )
                            widget.image = newImage?.url
                            widget.imageId = newImage?.id
                            widget.thumbnail = newImage?.thumbnail
                        }
                    }
                }
            }
            return values
        }
    }

    async function handleSubmit(e) {
        e.preventDefault()
        setSubmitting(true)
        try {
            // console.log(" form : ", form)

            const body = await handleImageUpload(form)

            if (!body.slug) {
                body.slug = params.slug
                body.storeCode = params.slug
            }

            await updateCmsSection(params.slug, body, token)
            message.success("Elementi aggiornati con successo")
            // fetchHomeContent && fetchHomeContent()
        } catch {
            message.error("Errore durante l'aggiornamento degli elementi")
        }
        setSubmitting(false)
    }

    function handleWidgetToggled(widget) {
        const isOpen = widget.id === openWidget
        setOpenwidget(isOpen ? null : widget.id)
    }

    function handleWidgetDelete(widget) {
        const newWidgets = form?.widgets?.filter((w) => w.id !== widget.id)
        setOne("widgets", newWidgets)
    }

    function handleWidgetMove(index, direction) {
        let targetIndex = index
        if (direction === "up") {
            if (index > 0) {
                targetIndex = index - 1
            }
        } else if (direction === "down") {
            if (index < form.widgets.length - 1) {
                targetIndex = index + 1
            }
        }
        const cache = form.widgets[targetIndex]
        const newWidgets = [...form.widgets]
        newWidgets[targetIndex] = form.widgets[index]
        newWidgets[index] = cache
        setOne("widgets", newWidgets)
    }

    const isDraggableValueSortableItem = (valueReturn) => {
        setValueIsDraggingChild(valueReturn)
    }

    // const handleClickZIndex = () => {
    //     setValueIsDraggingChild(!valueIsDraggingChild)
    // }
    if (valueIsDraggingChild === true) {
        console.log(valueIsDraggingChild)
    }

    function renderDynamicWidget(widget, index) {
        return (
            <div
                className={`widget widget-dynamic ${
                    widget.id === openWidget && "widget-open"
                }`}
                key={widget.id}
            >
                <div
                    onClick={() => handleWidgetToggled(widget)}
                    className={`widget-trigger `}
                >
                    <span className="widget-trigger-title">
                        {widget?.title}
                    </span>
                    <IoIosArrowDown className="widget-trigger-icon" />
                </div>
                <div
                    className="widget-content"
                >
                    <Collapsible open={widget.id === openWidget}>
                        <HeroEditor
                            className="hero-editor"
                            values={getWidget(widget.id)}
                            onChange={updateForm(widget.id)}
                            onDelete={handleWidgetDelete}
                            brandOptions={brandOptions}
                            categoryOptions={categoryOptions}
                            onMove={handleWidgetMove}
                            index={index}
                            draggableValueHeroFromChild={
                                isDraggableValueSortableItem
                            }
                            // onClick={handleClickZIndex}
                        />
                    </Collapsible>
                </div>
            </div>
        )
    }

    function renderWidget(widget, index) {
        if (widget) {
            // return widget.type === "static"
            //     ? renderStaticWidget(widget, index)
            //     : renderDynamicWidget(widget, index)
            return renderDynamicWidget(widget, index)
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <form onSubmit={handleSubmit} className={`${className}`}>
            <div className="top-row">
                <div className="drag-help-wrapper">
                    <IoIosInformationCircleOutline />

                    <span className="drag-help">
                        Trascina le sezioni per modificare l&apos;ordine degli
                        elementi. Le sezioni in grigio non si possono modificare
                        o eliminare.
                    </span>
                </div>
                <AppButton
                    uxType="secondary"
                    htmlType="button"
                    className="add-button"
                    onClick={handleAddWidget}
                >
                    Aggiungi widget
                </AppButton>
            </div>
            <div className="widgets">
                {form?.widgets?.map((widget, index) =>
                    renderWidget(widget, index)
                )}
            </div>
            <div className="buttons-row">
                <AppButton
                    uxType="delete"
                    htmlType="button"
                    className="button"
                    onClick={handleReset}
                >
                    Annulla modifiche
                </AppButton>
                <AppButton
                    uxType="primary"
                    htmlType="submit"
                    loading={submitting}
                >
                    Salva
                </AppButton>
            </div>
        </form>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Home.propTypes = {
    className: PropTypes.string.isRequired,
}

_Home.defaultProps = {}

// ----------------------------------------------------------------------------

const Home = styled(_Home)`
    & {
        .drag-help-wrapper {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            width: 100%;
            padding: 10px 5px;
            border-radius: 8px;
            margin-bottom: 20px;
            .drag-help {
                display: block;
                width: 100%;
                font-weight: 500;
                font-size: 14px;
            }

            svg {
                color: ${({ theme }) => theme.colors.success};
                margin-top: 3px;
                margin-right: 10px;
                font-size: 22px;
            }
        }
        .top-row {
            display: flex;
            flex-direction: row;
            max-width: 650px;
        }
        .add-button {
            height: 50px;
            display: block;
            margin-left: 10px;
        }
        .buttons-row {
            button {
                height: 48px;
                margin-right: 10px;
            }
        }
        .widgets {
            .widget {
                border-radius: 14px;
                width: 100%;
                border: 2px solid ${({ theme }) => theme.colors.primary};
                margin-bottom: 20px;
                padding: 20px 10px;
                .widget-trigger {
                    display: flex;
                    width: 100%;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    .widget-trigger-title {
                        font-size: 16px;
                        font-weight: 600;
                    }
                    .widget-trigger-icon {
                        font-weight: 600;
                        font-size: 22px;
                        transition: all 200ms ease;
                    }
                }

                .widget-content {
                    .hero-editor {
                        margin-top: 20px;
                    }
                }
                &.widget-open {
                    .widget-trigger .widget-trigger-icon {
                        transform: rotate(180deg);
                    }
                }
                &.widget-static {
                    background-color: ${({ theme }) =>
                        theme.colors.tabBarInactiveColor};
                    border: none !important;
                }
                &.widget-dynamic {
                    .widget-trigger {
                        cursor: pointer;
                    }
                }
            }
        }

        .buttons-row {
            margin-top: 30px;
        }
    }
`
// ----------------------------------------------------------------------------

const getNewWidget = () => ({
    type: "dynamic",
    subType: "slider",
    id: Math.random() * 1000000000,
    title: "",
    subtitle: "",
    view: null,
    targetType: null,
    target: null,
    image: "",
})

export default Home
