/* global process */
import { Base64 as base64 } from "js-base64"
import URLJoin from "url-join"

import {
    DEFAULT_API_PORT,
    executeDELETERequest,
    executeFormDataRequest,
    executeGETRequest,
    executePOSTRequest,
    executePUTRequest,
    urlWithQueryParams,
} from "../baseApi"

// ----------------------------------------------------------------------------

export const BASE_URL = getBaseURL()
const ACCESS_TOKEN = "eClpQj17UTYuVH5paGB9d0s="

// ----------------------------------------------------------------------------

function getBaseURL() {
    if (process.env.NODE_ENV === "development") {
        return `http://localhost:${DEFAULT_API_PORT}`
    } else {
        return "https://pevapp.prezzemoloevitale.it/api"
    }
}

// ----------------------------------------------------------------------------
// Auth
// ----------------------------------------------------------------------------

export function signUp({ ...userBody }) {
    const body = {
        ...userBody,
        access_token: ACCESS_TOKEN,
    }

    return executePOSTRequest(URLJoin(BASE_URL, "/users"), null, body)
}

export function signIn(email, password) {
    const encoded = base64.encode(`${email}:${password}`)
    const headers = {
        Authorization: `Basic ${encoded}`,
    }

    const body = { access_token: ACCESS_TOKEN }

    return executePOSTRequest(URLJoin(BASE_URL, "/auth"), null, body, headers)
}

// ----------------------------------------------------------------------------
// Users
// ----------------------------------------------------------------------------

export function createAdminUser({ email, password, name, surname }) {
    const body = {
        email,
        password,
        name,
        surname,
        role: "admin",
    }

    return signUp(body)
}

export function getUser(userId, token) {
    const url = `users/${userId}`

    return executeGETRequest(url, token)
}

export function updateUser(userId, body, token) {
    const relativeUrl = `/users/${userId}`

    const url = URLJoin(BASE_URL, relativeUrl)

    return executePUTRequest(url, token, body)
}

// ----------------------------------------------------------------------------
// SETTINGS
// ----------------------------------------------------------------------------
export function createSettings(values, token) {
    const relativeUrl = "/settings"
    const url = URLJoin(getBaseURL(), relativeUrl)

    return executePOSTRequest(url, token, values)
}
export function updateSettings(values, token) {
    const relativeUrl = "/settings"
    const url = URLJoin(getBaseURL(), relativeUrl)

    return executePUTRequest(url, token, values)
}
export function getSettings(token) {
    const relativeUrl = "/settings"
    const url = URLJoin(getBaseURL(), relativeUrl)

    return executeGETRequest(url, token)
}

// ----------------------------------------------------------------------------
// SHOPSTIMES
// ----------------------------------------------------------------------------
export function createShopsTimes(values, token) {
    const relativeUrl = "/shopstimes"
    const url = URLJoin(getBaseURL(), relativeUrl)

    return executePOSTRequest(url, token, values)
}
export function updateShopsTimes(id, values, token) {
    const relativeUrl = `/shopstimes/${id}`
    const url = URLJoin(getBaseURL(), relativeUrl)

    return executePUTRequest(url, token, values)
}
export function getShopsTimes(token) {
    const relativeUrl = "/shopstimes"
    const url = URLJoin(getBaseURL(), relativeUrl)

    return executeGETRequest(url, token)
}

// ----------------------------------------------------------------------------
// CMS
// ----------------------------------------------------------------------------

export function updateCmsSection(slug, values, token) {
    const relativeUrl = `/cms/${slug}`
    const url = URLJoin(getBaseURL(), relativeUrl)

    return executePUTRequest(url, token, values)
}

// ----------------------------------------------------------------------------
// Publish
// ----------------------------------------------------------------------------

export function publishCMS(token) {
    const url = URLJoin(getBaseURL(), "/cms/publish")
    return executePOSTRequest(url, token)
}

export function getCmsVersion(token) {
    const url = URLJoin(getBaseURL(), "/cms-version")
    return executeGETRequest(url, token)
}

// ----------------------------------------------------------------------------
// Account delete requests
// ----------------------------------------------------------------------------

export function whitelistAccount(email, token) {
    const url = URLJoin(getBaseURL(), "account-delete-requests", email)
    return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// Uploads
// ----------------------------------------------------------------------------

export async function uploadFile(file, token, path = "", isGif = false) {
    const relativeUrl = `/uploads`

    const url = URLJoin(BASE_URL, relativeUrl)

    const body = {
        file,
        path,
        isGif,
    }

    return executeFormDataRequest(url, "POST", null, body, token)
}

export async function deleteRemoteFile(fileUrl, token) {
    const relativeUrl = `/uploads?url=${fileUrl}`

    const url = URLJoin(BASE_URL, relativeUrl)

    return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
// REST Resources
// ----------------------------------------------------------------------------

export function getResourceList(resourceURI, token, options, user) {
    const { pageNumber, pageSize = 10, sort, filters } = options
    const url = URLJoin(BASE_URL, resourceURI)

    const filterParams = {}
    for (const filterKey in filters) {
        if (filters[filterKey].length) {
            if (Array.isArray(filters[filterKey])) {
                filterParams[filterKey] = filters[filterKey].join(",")
            } else {
                filterParams[filterKey] = filters[filterKey]
            }
        }
    }

    const params = {
        ...filterParams,
        limit: pageSize,
    }

    if (pageNumber) {
        params.page = pageNumber
    }

    if (sort) {
        const sortOperator = sort.order === "descend" ? "-" : "+"
        params.sort = `${sortOperator}${sort.key}`
    }

    const urlWithQuery = urlWithQueryParams(url, params)
    return executeGETRequest(
        urlWithQuery,
        token,
        { isList: true },
        user ? { User: `${user.id}` } : null
    )
}

export async function getResource(resourceURI, token, user) {
    const url = URLJoin(BASE_URL, resourceURI)

    return executeGETRequest(
        url,
        token,
        { isList: false },
        user ? { User: `${user.id}` } : null
    )
}

export function createResource(resourceURI, token, resource) {
    const url = URLJoin(BASE_URL, resourceURI)
    return executePOSTRequest(url, token, resource)
}

export function updateResource(resourceURI, token, resourceID, resource) {
    const url = URLJoin(BASE_URL, resourceURI, resourceID)

    return executePUTRequest(url, token, resource)
}

export function deleteResource(resourceURI, token, resourceID) {
    const url = URLJoin(BASE_URL, resourceURI, resourceID)
    return executeDELETERequest(url, token)
}

// ----------------------------------------------------------------------------
