import PropTypes from "prop-types"
import React, { useMemo, useState } from "react"
import styled, { css } from "styled-components"

import useCategories from "../../../hooks/useCategories"
import OptionsModal from "./OptionsModal"

// ----------------------------------------------------------------------------

function _CategorySelector(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, value, onChange, label } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [focused, setIsFocused] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const catree = useCategories()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    const currentCategory = useMemo(() => {
        function find(category, id) {
            if (!category) {
                return
            }
            if (category.id === id) {
                return category
            }
            if (category.children_data) {
                for (let i = 0; i < category.children_data.length; i++) {
                    const child = category.children_data[i]
                    const found = find(child, id)
                    if (found) {
                        return found
                    }
                }
            }
            return null
        }
        return find(catree, value)
    }, [catree, value])

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function onBlur() {
        setIsFocused(false)
    }

    function handleFocus() {
        setIsFocused(true)
    }

    function getStateClass() {
        let cssClass = ""
        if (focused) {
            cssClass = "focused"
        } else {
            cssClass = value ? "valid" : ""
        }

        return cssClass
    }

    function handleChange(category) {
        onChange(category)
        setIsFocused(false)
        setModalOpen(false)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className} ${getStateClass()}`}>
            <input
                className="selector"
                onFocus={handleFocus}
                onBlur={onBlur}
                type="button"
                value={currentCategory?.name}
                onClick={() => setModalOpen(true)}
            />
            <label className="input-label">{label}</label>
            <OptionsModal
                open={modalOpen}
                onOptionSelected={handleChange}
                onClose={() => setModalOpen(false)}
                catree={catree}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_CategorySelector.propTypes = {
    className: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func,
    label: PropTypes.string,
}

_CategorySelector.defaultProps = {}

// ----------------------------------------------------------------------------

const CategorySelector = styled(_CategorySelector)`
    & {
        position: relative;

        display: flex;
        flex-direction: column;
        border-radius: 6px;
        min-height: 48px;
        height: 48px;
        max-height: 48px;
        font-size: 14px;
        max-width: 300px;
        width: 100%;
        background-color: transparent;

        overflow: hidden;

        .selector {
            flex: 1;
            z-index: 2;
            border: 1px solid ${(props) => props.theme.colors.inputBorder};
            border-radius: 6px;
            padding: 0 10px 0 10px;
            height: 48px;
            max-height: 48px;
            background-color: transparent;
            font-size: 14px;
            color: ${(props) => props.theme.colors.dark};
            text-align: left;
            padding-top: 13px;
            cursor: pointer;
        }

        .input-label {
            position: absolute;
            top: 50%;

            transform: translateY(-50%);

            left: 10px;
            font-size: 15px;
            color: ${(props) => props.theme.colors.inputBorder};
            z-index: 1;
            transition: 0.3s ease all;
            -moz-transition: 0.3s ease all;
            -webkit-transition: 0.3s ease all;

            user-select: none;

            ${(props) =>
                props.disabled &&
                css`
                    z-index: 1;
                    color: #d6d6d6;
                    cursor: not-allowed;
                `}
        }

        &.focused,
        &.valid {
            .tm4-input {
                border-color: ${(props) =>
                    props.theme.colors.primary} !important;
                padding-top: 8px;
            }
            .input-label {
                color: ${({ theme }) => theme.colors.primary};
            }
        }

        .validation-error-message {
            position: absolute;
            top: 50%;
            left: 102%;
            width: 50%;
            transform: translateY(-50%);
            font-size: 10px;
            font-weight: 600;
            color: rgb(255, 59, 48);
        }

        /* MOBILE */
        @media (max-width: 576px) {
            .validation-error-message {
                position: absolute;
                top: 101%;
                left: 10px;
                width: 100%;
                transform: unset;
                line-height: 1;
            }
        }

        &.focused {
            .input-label {
                position: absolute;
                top: 8.5px;
                font-size: 10px;
                font-weight: 600;
                color: ${(props) => props.theme.colors.primaryColor};
            }
        }

        &.valid {
            .input-label {
                position: absolute;
                top: 8.5px;
                font-size: 10px;
                font-weight: 600;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default CategorySelector
