import { createContext, useContext } from "react"

import { useStateValue } from "../context/AppStateStore"
import useRestRestResource from "./useRestResource"

const CategoriesContext = createContext(null)

// eslint-disable-next-line react/prop-types
export const CategoriesProvider = ({ children }) => {
    const [{ token }] = useStateValue()
    const [catree] = useRestRestResource("/magento", "categories", token)
    return (
        <CategoriesContext.Provider value={catree}>
            {children}
        </CategoriesContext.Provider>
    )
}

const useCategories = () => useContext(CategoriesContext)

export default useCategories
