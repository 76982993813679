const colors = {
    secondary: "#F8F3ED",
    primary: "#5D9058",
    tertiary: "rgba(237, 80, 111, 0.5)",
    categoryTileBackground: "#BEC9CD",
    productTileBackground: "#F5F6F6",

    textPrimary: "#000000",
    textAccent: "#ED506F",
    textMuted: "#757575",
    textLight: "#FFFFFF",

    inputBorder: "#c4c4c4",
    tabBarInactiveColor: "#c4c4c4",

    brandDividerColor: "rgba(0,0,0,0.1)",

    grey200: "#F5F6F6",
    grey300: "#F5F5F5",
    grey350: "#DFDFDF",
    grey400: "#BCBCBC",
    grey500: "#C4C4C4",

    backgroundPrimary: "#FFFFFF",

    success: "#22A556",
    error: "red",
    error200: "#FFCDC6",
    pending: "orange",

    light: "#FFF",
    dark: "#000",
}

export default colors
