import { Checkbox, TimePicker, message } from "antd"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import {
    createSettings,
    createShopsTimes,
    getSettings,
    getShopsTimes,
    updateSettings,
    updateShopsTimes,
} from "../../../../api/Local"
import AppButton from "../../../_shared/components/AppButton"
import AppInput from "../../../_shared/components/FormItems/AppInput"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import { getShops } from "../../../../api/magento"
import { useShops2 } from "../CmsRoot/useShops"
import dayjs from "dayjs"

// ----------------------------------------------------------------------------

function _Stores(props) {
    // -------------------------------------

    const { className } = props
    const [formValue, setFormValue] = useState()

    const [{ token }] = useStateValue()

    const [shops, loading] = useShops2()

    function createHomeDeliveryShops() {
        let newShops = []
        const update =
            formValue.some((x) => Number(x.uid) == -3) &&
            formValue.some((x) => Number(x.uid) == -2) &&
            formValue.some((x) => Number(x.uid) == -1)

        !formValue.some((x) => Number(x.uid) == -3) &&
            newShops.push({ uid: -3, code: "shop_EU" })
        !formValue.some((x) => Number(x.uid) == -2) &&
            newShops.push({ uid: -2, code: "shop_UK" })
        !formValue.some((x) => Number(x.uid) == -1) &&
            newShops.push({ uid: -1, code: "shop_ITA" })

        !update && setFormValue([...formValue, ...newShops])
    }

    useEffect(() => {
        formValue && createHomeDeliveryShops()
    }, [formValue])

    useEffect(() => {
        fetchTimes()
    }, [loading, shops])

    async function fetchTimes() {
        try {
            const sets = await getShopsTimes(token)
            const newShops = shops?.filter((x) => {
                if (!sets?.rows?.find((y) => y?.uid === x?.uid)) {
                    return x
                }
            })

            if (sets.count > 0) {
                setFormValue([...sets?.rows, ...newShops])
            } else {
                setFormValue(shops)
            }
        } catch (error) {
            console.error("error: ", error.message)
        }
    }

    async function saveSettings(e) {
        try {
            const properties = formValue
            const shopTimes = await getShopsTimes(token)

            properties?.map((x) => {
                shopTimes?.rows?.some((shop) => shop.uid === x.uid)
                    ? console.log("update ", x.id)
                    : console.log("create ", x)
            })

            properties?.map(async (x) => {
                shopTimes?.rows?.some((shop) => shop.uid === x.uid)
                    ? await updateShopsTimes(x.id, x, token)
                    : await createShopsTimes(x, token)
            })
            await fetchTimes()
            message.success("Elementi aggiornati con successo")
        } catch (error) {
            console.log(e)
            message.error("Errore durante l'aggiornamento degli w")
        }
    }

    const updateTime = (day, prop, shop) => (value) => {
        const updatedContent = formValue?.map((w) => {
            if (w.uid === shop.uid) {
                const dayBackup = w?.[day]
                return {
                    ...w,
                    [day]: { ...dayBackup, [prop]: value },
                }
            } else return w
        })

        setFormValue(updatedContent)
    }

    const updateCheckbox = (day, prop, shop) => (value) => {
        const updatedContent = formValue?.map((w) => {
            if (w.uid === shop.uid) {
                const dayBackup = w?.[day]
                return {
                    ...w,
                    [day]: { ...dayBackup, [prop]: value.target.checked },
                }
            } else return w
        })

        setFormValue(updatedContent)
    }

    const updateForm = (prop, shop) => (value) => {
        const updatedContent = formValue?.map((w) => {
            if (w.uid === shop.uid) {
                return {
                    ...w,
                    [prop]: value,
                }
            } else return w
        })

        setFormValue(updatedContent)
    }

    const format = "HH:mm"
    const weekDays = [
        { key: "monday", value: "Lunedí" },
        { key: "tuesday", value: "Martedí" },
        { key: "wednesday", value: "Mercoledí" },
        { key: "thursday", value: "Giovedí" },
        { key: "friday", value: "Venerdí" },
        { key: "saturday", value: "Sabato" },
        { key: "sunday", value: "Domenica" },
    ]
    return (
        <div className={`${className}`}>
            <div>
                <div style={{ marginTop: 40, marginBottom: 100 }}>
                    <h1 className="page-title">Consegna a casa</h1>
                    {formValue
                        ?.filter((x) => Number(x.uid) < 0)
                        .map((shop) => {
                            return (
                                <div>
                                    <h3
                                        style={{
                                            marginBottom: 10,
                                            marginTop: 30,
                                        }}
                                    >
                                        {shop.code}
                                    </h3>
                                    <h4>Numero di telefono</h4>
                                    <AppInput
                                        value={shop?.phoneNumber}
                                        onChange={updateForm(
                                            "phoneNumber",
                                            shop
                                        )}
                                    />
                                    <h4>Mail</h4>
                                    <AppInput
                                        value={shop?.mail}
                                        onChange={updateForm("mail", shop)}
                                    />
                                </div>
                            )
                        })}
                </div>
                <h1 className="page-title">Negozi</h1>
                <div style={{ marginTop: 30, marginBottom: 30 }}>
                    {formValue
                        ?.filter((x) => Number(x.uid) > 0)
                        .map((shop) => {
                            return (
                                <div
                                    style={{ marginTop: 30, marginBottom: 100 }}
                                >
                                    <h3
                                        style={{
                                            marginBottom: 10,
                                            marginTop: 30,
                                        }}
                                    >
                                        {shop.code}
                                    </h3>
                                    <h4>Numero di telefono</h4>
                                    <AppInput
                                        value={shop?.phoneNumber}
                                        onChange={updateForm(
                                            "phoneNumber",
                                            shop
                                        )}
                                    />
                                    <h4>Mail</h4>
                                    <AppInput
                                        value={shop?.mail}
                                        onChange={updateForm("mail", shop)}
                                    />
                                    {weekDays.map((day) => (
                                        <div style={{ marginTop: 20 }}>
                                            <h3>{day.value}</h3>
                                            <Checkbox
                                                checked={
                                                    shop[day.key]?.continuedTime
                                                }
                                                onChange={updateCheckbox(
                                                    day.key,
                                                    "continuedTime",
                                                    shop
                                                )}
                                            >
                                                Orario continuato
                                            </Checkbox>
                                            <Checkbox
                                                checked={
                                                    shop[day.key]?.isClosed
                                                }
                                                onChange={updateCheckbox(
                                                    day.key,
                                                    "isClosed",
                                                    shop
                                                )}
                                            >
                                                Chiuso
                                            </Checkbox>
                                            {shop[day.key]?.continuedTime ? (
                                                <div>
                                                    DALLE{"  "}
                                                    <TimePicker
                                                        minuteStep={15}
                                                        hourStep={1}
                                                        format={format}
                                                        defaultValue={dayjs(
                                                            shop[day.key]
                                                                ?.morningStartTime
                                                        )}
                                                        onChange={updateTime(
                                                            day.key,
                                                            "morningStartTime",
                                                            shop
                                                        )}
                                                    />
                                                    {"  "}ALLE{"  "}
                                                    <TimePicker
                                                        minuteStep={15}
                                                        hourStep={1}
                                                        format={format}
                                                        defaultValue={dayjs(
                                                            shop[day.key]
                                                                ?.morningStopTime
                                                        )}
                                                        onChange={updateTime(
                                                            day.key,
                                                            "morningStopTime",
                                                            shop
                                                        )}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <div>
                                                        MATTINA DALLE{"  "}
                                                        <TimePicker
                                                            minuteStep={15}
                                                            hourStep={1}
                                                            format={format}
                                                            defaultValue={dayjs(
                                                                shop[day.key]
                                                                    ?.morningStartTime
                                                            )}
                                                            onChange={updateTime(
                                                                day.key,
                                                                "morningStartTime",
                                                                shop
                                                            )}
                                                        />
                                                        {"  "}ALLE{"  "}
                                                        <TimePicker
                                                            minuteStep={15}
                                                            hourStep={1}
                                                            format={format}
                                                            defaultValue={dayjs(
                                                                shop[day.key]
                                                                    ?.morningStopTime
                                                            )}
                                                            onChange={updateTime(
                                                                day.key,
                                                                "morningStopTime",
                                                                shop
                                                            )}
                                                        />
                                                    </div>
                                                    {"  "}POMERIGGIO DALLE{"  "}
                                                    <TimePicker
                                                        minuteStep={15}
                                                        hourStep={1}
                                                        format={format}
                                                        defaultValue={dayjs(
                                                            shop[day.key]
                                                                ?.afternoonStartTime
                                                        )}
                                                        onChange={updateTime(
                                                            day.key,
                                                            "afternoonStartTime",
                                                            shop
                                                        )}
                                                    />
                                                    {"  "}
                                                    ALLE{"  "}
                                                    <TimePicker
                                                        minuteStep={15}
                                                        hourStep={1}
                                                        format={format}
                                                        defaultValue={dayjs(
                                                            shop[day.key]
                                                                ?.afternoonStopTime
                                                        )}
                                                        onChange={updateTime(
                                                            day.key,
                                                            "afternoonStopTime",
                                                            shop
                                                        )}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )
                        })}{" "}
                </div>
            </div>

            <div className="buttons-row">
                <AppButton onClick={saveSettings}>Salva</AppButton>
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Stores.propTypes = {
    className: PropTypes.string,
}

// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
const Stores = styled(_Stores)`
    & {
    }
`
export default Stores
