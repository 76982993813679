import { useState } from "react"

/**
 *
 * @typedef Validator
 * @property {(item: any) => boolean | string} validator
 * Function to be applied to the item.
 * If it returns true, the item is valid.
 * If it returns false, the item is invalid.
 * If it returns a string, the item is invalid and the string will be used as the message.
 * @property {string?} message
 * Message to be applied to the response if the item is invalid.
 * @property {boolean?} array
 * Specifies that the item to be validated is an array and `validator`
 * should be applied to each item.
 * @property {string?} key
 * Specifies the key under whiche errors generated by this object
 * will be stored in the object returned by the validation.
 * @property {((item: any) => any) | undefined} keyExtractor
 * If item to be validated is an array, each sub-object will have a
 * `key` field computed by this function.
 */

/**
 * @typedef ValidatorReturnType
 * If the target object is an array, there will be an array if this object, one for
 * each item in the original array.
 * @property {boolean} invalid
 * Whether target object is invalid.
 * @property {string} message
 * Message to be displayed if invalid.
 * @property {number?} index
 * If target object is an array, this is the index in the array that produced the object.
 * @property {string?} key
 * If target object is an array, this is the key generated by `Validator.keyExtractor` that produced the object.
 */

/**
 * @typedef {{[key: string]: ValidatorReturnType | ValidatorReturnType[]}} Errors
 */

/**
 *
 * @param {Object} defaultValues
 * @param {{[key: string]: Validator | Validator[]}} validators
 * @returns {[{[key:string]: any}; (field: string, item: any) => void; (items: Object) => void; () => void; () => {errorWasFound: boolean; errors: Errors}]}
 */
export default function useForm(defaultValues, validators) {
    const [form, setForm] = useState(defaultValues ?? {})

    function updateOne(key, value) {
        setForm({ ...form, [key]: value })
    }

    function updateMany(values) {
        setForm({ ...form, ...values })
    }

    function clear() {
        setForm({})
    }

    function validate() {
        let errorWasFound = false
        const errors = {}
        Object.entries(validators).forEach(([field, operation]) => {
            if (Array.isArray(operation)) {
                operation.forEach((op) => {
                    const foundErrors = performValidation(
                        op,
                        field,
                        errors,
                        op.key ?? field,
                    )
                    if (!errorWasFound) errorWasFound = foundErrors
                })
            } else {
                const foundErrors = performValidation(operation, field, errors)
                if (!errorWasFound) errorWasFound = foundErrors
            }
        })
        return { errorWasFound, errors }
    }

    function performValidation(operation, field, errors, errorKey) {
        let errorWasFound = false
        if (operation.array) {
            const nestedErrorFound = validateArray(
                operation.key ?? field,
                form?.[field],
                operation,
                errors,
            )
            if (nestedErrorFound) {
                errorWasFound = true
            }
        } else {
            const result = operation.validator(form?.[field], form)
            if (
                result &&
                !(typeof result === "string") &&
                !typeof result === "object" &&
                result !== null
            ) {
                errors[errorKey ?? field] = { invalid: false, message: null }
            } else {
                errorWasFound = true
                const hasCustomMessage = typeof result === "string"
                errors[errorKey ?? field] = {
                    invalid: true,
                    message: hasCustomMessage ? result : operation.message,
                }
            }
        }
        return errorWasFound
    }

    function validateArray(field, values, operation, errors) {
        const localErrors = []
        let localErrorFound = false
        values?.forEach((value, index) => {
            if (
                operation.validator(value, values) &&
                !(typeof operation.validator(value, values) === "string")
            ) {
                localErrors.push({
                    invalid: false,
                    message: null,
                    index,
                    key: operation?.keyExtractor?.(value),
                })
            } else {
                const message =
                    operation.validator(value, values) ?? operation.message
                localErrorFound = true
                localErrors.push({
                    index,
                    invalid: true,
                    key: operation?.keyExtractor?.(value),
                    message:
                        typeof message === "string"
                            ? message
                            : operation.message,
                })
            }
        })
        errors[field] = localErrors
        return localErrorFound
    }

    return [form, updateOne, updateMany, clear, validate]
}
