import PropTypes from "prop-types"
import React from "react"
import { AiOutlineArrowLeft } from "react-icons/ai"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import AppButton from "../../../_shared/components/AppButton"
import Home from "./Home"

// ----------------------------------------------------------------------------

function _Cms(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const navigate = useNavigate()

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <h1 className="page-title">
                <AppButton
                    onClick={() => navigate(-1)}
                    uxType="secondary"
                    type="button"
                    className="back"
                >
                    <AiOutlineArrowLeft />
                </AppButton>
                <span>CMS | Prezzemolo APP</span>
            </h1>
            <Home />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Cms.propTypes = {
    className: PropTypes.string.isRequired,
}

_Cms.defaultProps = {}

// ----------------------------------------------------------------------------

const Cms = styled(_Cms)`
    & {
        h1 {
            display: flex;
            gap: 10px;
            align-items: center;
        }
        .back {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 50px;
            padding: 0;
            svg {
                height: 20px;
                width: 20px;
            }
        }
        .routes {
            width: 100%;
            .route {
                height: 50px;
                width: 100%;
                padding-left: 10px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid ${({ theme }) => theme.colors.grey500};
                color: ${({ theme }) => theme.colors.textPrimary};
                transition: all 200ms ease;
                font-size: 16px;

                &:hover {
                    background-color: ${({ theme }) => theme.colors.grey200};
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default Cms
