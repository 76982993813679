/* global process */
import urlJoin from "url-join"

import { DEFAULT_API_PORT, executeGETRequest } from "../baseApi"

function getBaseURL() {
    if (process.env.NODE_ENV === "development") {
        return `http://localhost:${DEFAULT_API_PORT}/magento`
    } else {
        return "https://pevapp.prezzemoloevitale.it/api/magento"
    }
}

export function getShops(token) {
    const url = urlJoin(getBaseURL(), "/shops")
    return executeGETRequest(url, token)
}

export function getStoreGroups(token) {
    const url = urlJoin(getBaseURL(), "/store-configs")
    return executeGETRequest(url, token)
}

export function getProducts(search, token) {
    const url = urlJoin(getBaseURL(), `/products?name=${search}`)
    return executeGETRequest(url, token)
}
