// import "./App.css"

import "./index.css"

import PropTypes from "prop-types"
import React from "react"
import { ThemeProvider } from "styled-components"

import RoutedApp from "./RoutedApp"
import colors from "./_shared/constants/colors"
import { AppStateStore } from "./_shared/context/AppStateStore"
import { CategoriesProvider } from "./_shared/hooks/useCategories"

// ----------------------------------------------------------------------------

function App() {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const initialState = {
        user: null,
        colors: "dark",
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case "SIGN_IN_FULFILLED": {
                return {
                    ...state,
                    token: action.payload.token,
                    user: action.payload.user,
                }
            }

            case "SIGN_OUT": {
                return {
                    ...state,
                    user: null,
                    token: null,
                }
            }

            default: {
                return {
                    ...state,
                }
            }
        }
    }

    const theme = {
        colors,
    }

    return (
        <AppStateStore
            storeKey="prezzemolo-admin-app"
            initialState={initialState}
            reducer={reducer}
        >
            <ThemeProvider theme={theme}>
                <CategoriesProvider>
                    <RoutedApp />
                </CategoriesProvider>
            </ThemeProvider>
        </AppStateStore>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

App.propTypes = {
    className: PropTypes.string.isRequired,
}

App.defaultProps = {}

// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------

export default App
