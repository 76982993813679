import { Input, Modal } from "antd"
import PropTypes from "prop-types"
import React, { useMemo, useState } from "react"
import { AiOutlineSearch } from "react-icons/ai"
import styled from "styled-components"

// ----------------------------------------------------------------------------

function _OptionsModal(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, open, onClose, onOptionSelected, catree } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [search, setSearch] = useState("")

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    const categoryOptions = useMemo(() => {
        const options = []

        function matches(category) {
            if (!search) {
                return true
            }
            const pat = new RegExp(search, "i")
            return pat.test(category.name)
        }

        function discover(category, prefix = "") {
            const match = matches(category)
            if (prefix && match) {
                options.push({
                    label: category.name,
                    fullPath: `${prefix} > ${category.name}`,
                    value: category.id,
                })
            } else if (match) {
                options.push({
                    label: category.name,
                    fullPath: category.name,
                    value: category.id,
                })
            }
            prefix = prefix ? `${prefix} > ${category.name}` : category.name

            if (category.children_data) {
                category.children_data.forEach((child) =>
                    discover(child, prefix)
                )
            }
        }

        if (catree && catree.children_data) {
            catree.children_data.forEach((category) => discover(category))
        }
        return options
    }, [catree, search])

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function removeLast(fullPath) {
        const parts = fullPath.split(" > ")
        if (parts.length === 1) {
            return fullPath
        }
        parts.pop()
        return parts.join(" > ")
    }

    function renderOption({ label, fullPath, value }) {
        return (
            <button
                key={value}
                className="category"
                onClick={() => onOptionSelected(value)}
            >
                <span className="full-path">{removeLast(fullPath)}</span>
                <span className="label">{label}</span>
            </button>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <Modal
            open={open}
            onCancel={onClose}
            footer={false}
            title="Seleziona categoria"
            width={600}
        >
            <div className={`${className}`}>
                <div className="filters">
                    <Input
                        value={search}
                        onChange={(evt) => setSearch(evt.target.value)}
                        placeholder="Cerca"
                        suffix={<AiOutlineSearch />}
                        className="filter"
                    />
                </div>
                <div className="categories">
                    {categoryOptions.map(renderOption)}
                </div>
            </div>
        </Modal>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_OptionsModal.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onOptionSelected: PropTypes.func.isRequired,
    catree: PropTypes.object.isRequired,
}

_OptionsModal.defaultProps = {}

// ----------------------------------------------------------------------------

const OptionsModal = styled(_OptionsModal)`
    & {
        margin-top: 10px;
        max-height: 80vh;
        overflow-y: auto;
        .filters {
            .filter {
                width: 200px;
                svg {
                    color: rgba(0, 0, 0, 0.45);
                }
            }
        }

        .categories {
            display: flex;
            flex-direction: column;
            gap: 4px;
            margin-top: 10px;

            .category {
                display: block;
                width: 100%;
                border: none;
                background: #fff;
                padding: 10px 10px;
                text-align: left;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                > span {
                    display: block;
                }

                .full-path {
                    font-size: 11px;
                    color: #a8a8a8;
                }
                .label {
                    font-size: 15px;
                    font-weight: 500;
                }

                &:hover {
                    background-color: #e7e7e7;
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default OptionsModal
