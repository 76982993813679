import PropTypes from "prop-types"
import React from "react"
import { Route, Routes } from "react-router-dom"
import styled from "styled-components"

import Layout from "../../_shared/components/Layout"
import Cms from "./CMS"
import CmsRoot from "./CmsRoot"

// import { getCmsVersion } from "../../../api/Local"
// import Layout from "../../_shared/components/Layout"
// import { useStateValue } from "../../_shared/context/AppStateStore"
// import AccountDeleteRequests from "./AccountDeleteRequests"
// import CMS from "./CMS"
// import Discount from "./Discount"
// import FreeShippingThreshold from "./FreeShippingThreshold"
// import Worlds from "./Worlds"
import Settings from "./Settings"
import Stores from "./Stores"

// ----------------------------------------------------------------------------

function _LoggedApp(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // const [{ token }] = useStateValue()

    // -------------------------------------
    // Effects
    // -------------------------------------

    // useEffect(() => {
    //     // eslint-disable-next-line
    //     setImmediate(async () => {
    //         if (token) {
    //             const version = await getCmsVersion(token).catch((e) => {
    //                 console.log(e)
    //                 return null
    //             })
    //             if (!version) {
    //                 Modal.warning({
    //                     title: "Non è stato possibile determinare la versione del CMS remoto.",
    //                     content: `
    //                     Questo potrebbe essere causato dal fatto che il CMS versionato non è mai stato esportato a Storeden.
    //                     Verifica che i mondi che devono essere mostrati in-app siano abilitati
    //                     (di default tutti i mondi sono nascosti) ed esporta il cms così che venga impostata una versione.
    //                     `,
    //                 })
    //             }
    //         }
    //     })
    // }, [token])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <Layout className={`${className}`}>
            <Routes>
                <Route path="/cms" exact element={<CmsRoot />} />
                <Route path="/cms/:slug" element={<Cms />} />
                <Route path="/settings" Component={Settings} />
                <Route path="/stores" Component={Stores} />
            </Routes>
            {/* //     <Switch>
        //          <Route exact path="/" component={Worlds} /> 
        //         <Routes>

        //             <Route path="/cms" component={<Cms/>} />
        //         </Routes>
        //         {/* <Route path="/discount" component={Discount} />
        //         <Route path="/shipping" component={FreeShippingThreshold} />
        //         <Route
        //             path="/account-delete-requests"
        //             component={AccountDeleteRequests}
        //         />
        //         <Route path="/settings" component={Settings} /> 
        //     </Switch> */}
        </Layout>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_LoggedApp.propTypes = {
    className: PropTypes.string.isRequired,
}

_LoggedApp.defaultProps = {}

// ----------------------------------------------------------------------------

const LoggedApp = styled(_LoggedApp)`
    & {
    }
`
// ----------------------------------------------------------------------------

export default LoggedApp
