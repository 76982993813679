import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai"
import { FiTrash2 } from "react-icons/fi"
import { DndContext, closestCenter } from "@dnd-kit/core"

import {
    SortableContext,
    horizontalListSortingStrategy,
} from "@dnd-kit/sortable"
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers"

import AppButton from "../../../../../_shared/components/AppButton"
import AppInput from "../../../../../_shared/components/FormItems/AppInput"
import AppSelect from "../../../../../_shared/components/FormItems/AppSelect"
import CategorySelector from "../../../../../_shared/components/FormItems/CategorySelector"
import StyledImageUploadInput from "../../../../../_shared/components/FormItems/ImageUploadInput"
import ProductSelector from "../../../../../_shared/components/FormItems/ProductSelector"
import SortableItem from "../SortableItem"

function getTargetOptions({ values, categoryOptions, brandOptions }) {
    switch (values?.targetType) {
        case "category":
            return categoryOptions

        case "brand":
            return brandOptions

        default:
            return []
    }
}

function getTargetFilterOption({ values }) {
    if (values?.targetType === "brand") {
        return (input, option) => {
            const pat = new RegExp(input?.toLowerCase())
            return pat.test(option?.children?.toLowerCase())
        }
    }
    if (values?.targetType === "category") {
        return (input, option) => {
            const pat = new RegExp(input?.toLowerCase())
            return pat.test(option?.children?.toLowerCase())
        }
    }
    return undefined
}

function renderArrows({ handleWidgetMove, index }) {
    return (
        <div className="arrows">
            <AppButton
                onClick={() => handleWidgetMove(index, "down")}
                className="arrow arrow-down"
                htmlType="button"
                uxType="secondary"
            >
                <AiOutlineArrowDown />
            </AppButton>
            <AppButton
                onClick={() => handleWidgetMove(index, "up")}
                className="arrow arrow-up"
                htmlType="button"
                uxType="secondary"
            >
                <AiOutlineArrowUp />
            </AppButton>
        </div>
    )
}

function renderTargetTypeSelector({
    values,
    onChange,
    categoryOptions,
    brandOptions,
}) {
    return (
        <AppSelect
            value={values?.target}
            onChange={onChange("target")}
            options={getTargetOptions({
                brandOptions,
                categoryOptions,
                values,
            })}
            filterOption={getTargetFilterOption({ values })}
            className="input"
            placeholder="Seleziona target"
            label="Target"
        />
    )
}

export function renderSliderNavigationSelectors({
    slide,
    onChangeSlide,
    brandOptions,
    slides,
    onChange,
}) {
    function updateSlide(newData) {
        const newSlide = { ...slide, ...newData }
        const newSlides = slides.map((s) => {
            if (s.id === slide.id) {
                return newSlide
            }
            return s
        })
        onChange("slides")(newSlides)
    }

    function renderTargetSelector() {
        const commonProps = {
            className: "input",
            label: "Target di navigazione",
            value: slide?.navigationTarget,
            onChange: onChangeSlide(slide.id, "navigationTarget"),
        }
        if (slide.navigationTargetType === "category") {
            return <CategorySelector {...commonProps} />
        } else if (slide.navigationTargetType === "brand") {
            return (
                <AppSelect
                    {...commonProps}
                    options={brandOptions}
                    filterOption={(input, option) => {
                        const pat = new RegExp(input?.toLowerCase())
                        return pat.test(option?.children?.toLowerCase())
                    }}
                />
            )
        } else if (slide.navigationTargetType === "product") {
            return (
                <ProductSelector
                    {...commonProps}
                    cachedOptions={
                        slide?.navigationTargetProduct && [
                            {
                                label: slide.navigationTargetProduct.name,
                                value: slide.navigationTargetProduct.sku,
                            },
                        ]
                    }
                    onChange={(product) => {
                        updateSlide({
                            navigationTarget: product?.sku,
                            navigationTargetProduct: product,
                        })
                    }}
                />
            )
        } else if (slide.navigationTargetType === "website") {
            return (
                <AppInput
                    {...commonProps}
                    placeholder="Inserisci url"
                    onChange={(value) => {
                        updateSlide({
                            navigationTarget: value,
                        })
                    }}
                />
            )
        }
        return <AppInput disabled {...commonProps} />
    }

    const targetTypeOptions = [
        { label: "Categoria", value: "category" },
        { label: "Brand", value: "brand" },
        { label: "Prodotto", value: "product" },
        { label: "Pagina web", value: "website" },
    ]
    return (
        <>
            <AppSelect
                className="input"
                label="Tipologia target"
                value={slide?.navigationTargetType}
                onChange={(type) => {
                    updateSlide({
                        navigationTargetType: type,
                        navigationTarget: undefined,
                        navigationTargetProduct: undefined,
                    })
                }}
                options={targetTypeOptions}
            />
            {renderTargetSelector()}
        </>
    )
}

export function returnSliderWidget({
    className,
    values,
    widgetOptions,
    onChange,
    handleDelete,
    addSlide,
    deleteSlide,
    onChangeSlide,
    handleWidgetMove,
    index,
    brandOptions,
    onDragEnd,
    informationIsDragging
}) {
    // Information isDraggable when is True, in DndKit


    return (
        <div className={`${className}`}>
            <AppSelect
                className="input"
                value={values?.subType}
                options={widgetOptions}
                onChange={onChange("subType")}
                placeholder="Seleziona tipo"
                label="SottoTipo"
            />
            <AppInput
                className="input"
                value={values?.title}
                onChange={onChange("title")}
                label="Titolo"
            />

            {/* Contents of the slider */}
            {/* <div className="slider-slides">
                {values?.slides?.map((slide, index) => (
                    <div className="slider-slide">
                        <div className="widget-content">
                            <AppInput
                                className="input"
                                value={slide?.title}
                                onChange={onChangeSlide(slide.id, "title")}
                                label="Titolo"
                            />
                            <AppInput
                                className="input"
                                value={slide?.btnText}
                                onChange={onChangeSlide(slide.id, "btnText")}
                                label="Testo bottone"
                            />
                            {renderSliderNavigationSelectors({
                                slide,
                                onChangeSlide,
                                brandOptions,
                                slides: values?.slides,
                                onChange,
                            })}
                            <StyledImageUploadInput
                                image={slide?.image}
                                onUpload={onChangeSlide(slide.id, "image")}
                            />
                            <AppButton
                                className="slide-delete"
                                htmlType="button"
                                uxType="delete"
                                onClick={() => deleteSlide(index)}
                            >
                                <FiTrash2 />
                            </AppButton>
                        </div>
                    </div>
                ))}
            </div> */}

            {/* DRAG and DROP Libraries */}
            <div className="slider-slides">
                <DndContext
                    collisionDetection={closestCenter}
                    onDragEnd={onDragEnd}
                    modifiers={[restrictToHorizontalAxis]}
                >
                    <SortableContext
                        items={values?.slides ?? []}
                        strategy={horizontalListSortingStrategy}
                    >
                        {values?.slides?.map?.((slide, index) => (
                            <SortableItem
                                key={slide.id}
                                slide={slide}
                                onChange={onChange}
                                deleteSlide={deleteSlide}
                                index={index}
                                className={className}
                                values={values}
                                onChangeSlide={onChangeSlide}
                                brandOptions={brandOptions}
                                draggingvalue={informationIsDragging}
                            />
                        ))}
                    </SortableContext>
                </DndContext>
            </div>

            <div className="button-row">
                <AppButton htmlType="button" onClick={addSlide}>
                    Aggiungi slide
                </AppButton>

                <AppButton
                    uxType="delete"
                    htmlType="button"
                    className="delete-btn"
                    onClick={handleDelete}
                >
                    Elimina widget
                </AppButton>

                {renderArrows({ handleWidgetMove, index })}
            </div>
        </div>
    )
}

export function returnCategoriesBrandsWidget({
    className,
    values,
    widgetOptions,
    onChange,
    destinationOptions,
    handleDelete,
    brandOptions,
    categoryOptions,
    handleWidgetMove,
    index,
}) {
    return (
        <div className={`${className}`}>
            <AppSelect
                className="input"
                value={values?.subType}
                options={widgetOptions}
                onChange={onChange("subType")}
                placeholder="Seleziona tipo"
                label="Visualizzazione"
            />
            <AppInput
                className="input"
                value={values?.title}
                onChange={onChange("title")}
                label="Titolo"
            />
            <AppInput
                className="input"
                value={values?.buttonText}
                onChange={onChange("buttonText")}
                label="Testo bottone"
            />
            <AppSelect
                value={values?.targetType}
                onChange={onChange("targetType")}
                options={destinationOptions}
                className="input"
                placeholder="Seleziona categoria"
                label="Categoria / Brand"
            />
            {renderTargetTypeSelector({
                brandOptions,
                categoryOptions,
                onChange,
                values,
            })}

            <div className="button-row">
                <AppButton
                    uxType="delete"
                    htmlType="button"
                    className="delete-btn"
                    onClick={handleDelete}
                >
                    Elimina widget
                </AppButton>

                {renderArrows({ handleWidgetMove, index })}
            </div>
        </div>
    )
}

export function returnHighlightedProductsWidget({
    className,
    values,
    widgetOptions,
    onChange,
    onChangeSlide,
    deleteSlide,
    addSlide,
    handleDelete,
    handleWidgetMove,
    index,
}) {
    return (
        <div className={className}>
            <AppSelect
                className="input"
                value={values?.subType}
                options={widgetOptions}
                onChange={onChange("subType")}
                placeholder="Seleziona tipo"
                label="Visualizzazione"
            />
            <AppInput
                className="input"
                value={values?.title}
                onChange={onChange("title")}
                label="Titolo"
            />
            <div className="slider-slides">
                {values?.slides?.map((slide, index) => (
                    <div className="slider-slide" key={slide.id}>
                        <div className="widget-content">
                            <ProductSelector
                                value={slide?.product?.sku}
                                onChange={onChangeSlide(slide.id, "product")}
                                cachedOptions={
                                    slide?.product && [
                                        {
                                            label: slide.product.name,
                                            value: slide.product.sku,
                                        },
                                    ]
                                }
                                className="input slide-select"
                                placeholder="Seleziona prodotto"
                                label="Prodotto"
                            />
                            <AppButton
                                className="slide-delete"
                                htmlType="button"
                                uxType="delete"
                                onClick={() => deleteSlide(index)}
                            >
                                <FiTrash2 />
                            </AppButton>
                        </div>
                    </div>
                ))}
            </div>

            <div className="button-row">
                <AppButton htmlType="button" onClick={addSlide}>
                    Aggiungi prodotto
                </AppButton>

                <AppButton
                    uxType="delete"
                    htmlType="button"
                    className="delete-btn"
                    onClick={handleDelete}
                >
                    Elimina widget
                </AppButton>

                {renderArrows({ handleWidgetMove, index })}
            </div>
        </div>
    )
}

export function returCategoriesHighWidget({
    className,
    values,
    widgetOptions,
    onChange,
    onChangeSlide,
    deleteSlide,
    addSlide,
    handleDelete,
    handleWidgetMove,
    index,
}) {
    return (
        <div className={className}>
            <AppSelect
                className="input"
                value={values?.subType}
                options={widgetOptions}
                onChange={onChange("subType")}
                placeholder="Seleziona tipo"
                label="Visualizzazione"
            />
            <AppInput
                className="input"
                value={values?.title}
                onChange={onChange("title")}
                label="Titolo"
            />
            <div className="slider-slides">
                {values?.slides?.map((slide, index) => (
                    <div className="slider-slide" key={slide.id}>
                        <div className="widget-content">
                            <CategorySelector
                                value={slide?.category}
                                onChange={onChangeSlide(slide.id, "category")}
                                className="input slide-select"
                                placeholder="Seleziona categoria"
                                label="Categoria"
                            />
                            <AppInput
                                className="input"
                                value={slide?.nameOverride}
                                onChange={onChangeSlide(
                                    slide.id,
                                    "nameOverride"
                                )}
                                label="Nome visualizzato"
                            />
                            <StyledImageUploadInput
                                image={slide?.image}
                                onUpload={onChangeSlide(slide.id, "image")}
                            />
                            <AppButton
                                className="slide-delete"
                                htmlType="button"
                                uxType="delete"
                                onClick={() => deleteSlide(index)}
                            >
                                <FiTrash2 />
                            </AppButton>
                        </div>
                    </div>
                ))}
            </div>

            <div className="button-row">
                <AppButton htmlType="button" onClick={addSlide}>
                    Aggiungi categoria
                </AppButton>

                <AppButton
                    uxType="delete"
                    htmlType="button"
                    className="delete-btn"
                    onClick={handleDelete}
                >
                    Elimina widget
                </AppButton>

                {renderArrows({ handleWidgetMove, index })}
            </div>
        </div>
    )
}
