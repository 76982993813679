import { Select } from "antd"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"
import styled from "styled-components"

// ----------------------------------------------------------------------------

const { Option } = Select

// ----------------------------------------------------------------------------

function _AppSelect(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const {
        className,
        value,
        options,
        onChange,
        searchValue,
        onSearch,
        label,
        filterOption,
        allowClear,
        loading,
    } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const [isFocused, setIsFocused] = useState(false)
    const selectRef = useRef()

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleFocus() {
        setIsFocused(true)
    }

    function handleBlur() {
        setIsFocused(false)
    }

    function renderOptions() {
        return options?.map(({ value, label }) => (
            <Option key={value} value={value}>
                {label}
            </Option>
        ))
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div
            className={`${className} my-select ${
                (isFocused || value || value === null) && "focused"
            }`}
        >
            <Select
                className="inner-select"
                value={value}
                onChange={onChange}
                placeholder={null}
                searchValue={isFocused && searchValue}
                onSearch={onSearch}
                filterOption={filterOption ?? false}
                showSearch={!!(onSearch || filterOption)}
                onFocus={handleFocus}
                onBlur={handleBlur}
                ref={selectRef}
                allowClear={allowClear}
                loading={loading}

                // showSearch={onSearch ? true : false}
            >
                {renderOptions()}
            </Select>
            <span
                onClick={() => selectRef?.current?.focus()}
                className={`label `}
            >
                {label}
            </span>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AppSelect.propTypes = {
    className: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    searchValue: PropTypes.string,
    onSearch: PropTypes.func,
    label: PropTypes.string,
    filterOption: PropTypes.any,
    allowClear: PropTypes.bool,
    loading: PropTypes.bool,
}

_AppSelect.defaultProps = {}

// ----------------------------------------------------------------------------

const AppSelect = styled(_AppSelect)`
    & {
        min-width: 300px;
        height: 48px;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        border: 1px solid ${({ theme }) => theme.colors.inputBorder};

        * {
            background-color: transparent !important;
        }

        .inner-select {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            display: block;
        }
        .ant-select-selector {
            border-radius: 6px !important;
            height: 48px !important;
            box-shadow: none !important;
            border: none !important;
            padding-top: 5px !important;
            /* border-color: ${({ theme }) =>
                theme.colors.inputBorder} !important; */
            .ant-select-selection-item {
                line-height: 48px !important;
            }
            &.ant-select-open {
                border: none !important;
            }
        }
        &:hover {
            .ant-select-selector {
                box-shadow: none !important;
                border: none !important;
            }
        }
        &.ant-select-focused {
            /* border: 1px solid ${({ theme }) =>
                theme.colors.inputBorder} !important; */
            .ant-select-selector {
                box-shadow: none !important;
                border: none !important;
            }
        }
        .ant-select-selection-placeholder {
            display: flex;
            align-items: center;
        }
        .ant-select-selection-search {
            display: flex;
            align-items: center;
            margin-top: 8.5px;
        }

        /* background: #ffffff;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
        .ant-select-selector {
            border: none !important;
            outline: none;
            height: 48px !important;
            .ant-select-selection-item {
                line-height: 48px;
                font-size: 16px;
                font-weight: 700;
            }
        }
        &.ant-select-open {
        } */

        .label {
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 10px;
            transition: top 200ms ease, font-size 200ms ease, color 200ms ease;
            font-size: 15px;
            font-weight: 400;
        }
        &.focused {
            border-color: ${({ theme }) => theme.colors.primary};
            .label {
                color: ${({ theme }) => theme.colors.primary};
                top: 8.5px;
                font-size: 10px;
                /* transform: translateY(0%); */
                font-weight: 600;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default AppSelect

export { Option }
