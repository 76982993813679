import URLJoin from "url-join"

// ----------------------------------------------------------------------------

export class APINotOkError extends Error {
    constructor(statusCode, serverResponse) {
        const message =
            (serverResponse && serverResponse.message) ||
            `HTTP status code: ${statusCode}`
        super(message)
        this.name = "APINotOkError"
        this.statusCode = statusCode
        this.serverMessage = serverResponse && serverResponse.state
        this.errorKey = serverResponse && serverResponse.key
    }
}

// ----------------------------------------------------------------------------

export const DEBUG_API = false
export const DEFAULT_API_PORT = "3001"

// ----------------------------------------------------------------------------
// Private helpers
// ----------------------------------------------------------------------------

export function executeGETRequest(url, token, options, extraHeaders) {
    const headers = {
        Authorization: token?.includes("apiKey") ? token : `Bearer ${token}`,
        ...extraHeaders,
    }

    return executeRequest(url, "get", null, headers, options)
}

export function executePOSTRequest(url, token, body, headers) {
    const newHeaders = headers || {}
    if (token) {
        newHeaders["Authorization"] = token.includes("apiKey")
            ? token
            : `Bearer ${token}`
    }
    return executeRequest(url, "post", body, newHeaders)
}

export function executePUTRequest(url, token, body, headers) {
    const newHeaders = headers || {}
    if (token) {
        newHeaders["Authorization"] = token.includes("apiKey")
            ? token
            : `Bearer ${token}`
    }
    return executeRequest(url, "put", body, newHeaders)
}

export function executeDELETERequest(url, token, headers) {
    const defaultHeaders = {
        Authorization: `Bearer ${token}`,
    }
    return executeRequest(url, "delete", null, headers || defaultHeaders)
}

export async function executeFormDataRequest(
    givenUrl,
    method,
    headers,
    body,
    token
) {
    const url = normalizeURL(givenUrl)

    const formData = new FormData()

    // format json body into form data
    body &&
        Object.keys(body).forEach((key) => {
            if (Array.isArray(body[key])) {
                const array = body[key]

                array.forEach((data, index) => {
                    const formDataArrayKey = `${key}`
                    formData.append(formDataArrayKey, data)
                })
            } else {
                formData.append(key, body[key])
            }
        })

    const requestData = {
        method,
        headers: new Headers({
            ...headers,
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        }),

        body: formData,
    }

    try {
        const response = await fetch(url, requestData)

        let responseBody = {}

        if (response && response.ok) {
            const responseBodyText = await response.text()

            try {
                responseBody = JSON.parse(responseBodyText)
            } catch {
                responseBody = responseBodyText
            }

            return responseBody
        } else {
            throw new APINotOkError(response.status, responseBody)
        }
    } catch (error) {
        throw new Error(error)
    }
}

function executeRequest(givenURL, method, givenBody, givenHeaders, options) {
    const url = normalizeURL(givenURL)
    const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...givenHeaders,
    }
    let body
    if (givenBody) {
        body = JSON.stringify(givenBody)
        if (DEBUG_API) {
            // eslint-disable-next-line
            console.debug(`[API/REQUEST/BODY]`, url, givenBody)
        }
    }
    const requestData = {
        method,
        headers,
        body,
    }
    if (DEBUG_API) {
        // eslint-disable-next-line
        console.debug(`[API/REQUEST]`, url, requestData)
    }

    return fetch(url, requestData).then((response) => {
        if (DEBUG_API) {
            // eslint-disable-next-line
            console.debug(`[API/RESPONSE]`, url, response)
        }
        return response.text().then(function (text) {
            let responseBody
            try {
                responseBody = JSON.parse(text)
            } catch {
                responseBody = text
            }

            if (DEBUG_API) {
                // eslint-disable-next-line
                console.debug(`[API/RESPONSE/BODY]`, responseBody)
            }

            if (response.ok) {
                if (options && options.isList) {
                    return {
                        data: responseBody,
                    }
                } else {
                    return responseBody
                }
            } else {
                throw new APINotOkError(response.status, responseBody)
            }
        })
    })
}

// -------------------------------------

export function normalizeURL(givenURL) {
    const isPathAbsolute =
        givenURL.indexOf("http://") === 0 || givenURL.indexOf("https://") === 0
    if (isPathAbsolute) {
        return givenURL
    } else {
        const baseURL = `${window.location.protocol}//${window.location.hostname}:${DEFAULT_API_PORT}`

        return URLJoin(baseURL, givenURL.replace(/^\.\//, ""))
    }
}

export function urlWithQueryParams(url, params) {
    const urlParams = Object.entries(params)
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join("&")
    return `${url}?${urlParams}`
}

// ----------------------------------------------------------------------------
