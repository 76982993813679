import { useEffect, useState } from "react"

import * as api from "../../../api/Local"

// import { useStateValue } from "../context/AppStateStore"

//------------------------------------------------------------------------------

export default function useRestResource(
    resourceUrl,
    resourceId,
    token,
    options
) {
    const defaultFilters = options || null

    // -------------------------------------

    const [resource, setResource] = useState([])
    const [filters, setFilters] = useState(defaultFilters)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        fetchResource()

        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        fetchResource()

        //eslint-disable-next-line
    }, [filters])

    // const [{ user }] = useStateValue()
    const user = undefined
    // -------------------------------------

    function urlWithQueryParams(url, params) {
        const urlParams = Object.entries(params)
            .map(
                ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
            )
            .join("&")
        return `${url}?${urlParams}`
    }

    // -------------------------------------

    function onFiltersChange(filters) {
        setFilters(filters)
    }

    async function fetchResource() {
        let url = `${resourceUrl}/${resourceId}`

        if (filters) {
            url = urlWithQueryParams(url, filters)
        }

        setIsLoading(true)
        try {
            const newResource = await api.getResource(url, token, user)

            setResource(newResource)
        } catch {
            setResource({})
        }
        setIsLoading(false)
    }

    // -------------------------------------

    return [resource, fetchResource, isLoading, onFiltersChange]
}
