import { message } from "antd"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import {
    createSettings,
    getSettings,
    updateSettings,
} from "../../../../api/Local"
import AppButton from "../../../_shared/components/AppButton"
import AppInput from "../../../_shared/components/FormItems/AppInput"
import { useStateValue } from "../../../_shared/context/AppStateStore"

// ----------------------------------------------------------------------------

function _Settings(props) {
    // -------------------------------------

    const { className } = props
    const [formValue, setFormValue] = useState()

    const [{ token }] = useStateValue()

    useEffect(() => {
        fetchSettings()
    }, [])

    async function fetchSettings() {
        try {
            const sets = await getSettings(token)
            setFormValue(sets)
        } catch (error) {
            console.error("error: ", error.message)
        }
    }

    function checkValue(value) {
        if (/^(\d+\.)?(\d+\.)?(\d+\.)?(\d+)$/.test(value)) {
            return true
        }
        return false
    }

    function onChange(field, text) {
        setFormValue({ ...formValue, [field]: text })
    }

    async function saveSettings(e) {
        try {
            if (!checkValue(formValue?.minVersionAcceptedApp)) {
                message.error("Valore versione minima IOS non valido")
                return
            }
            if (!checkValue(formValue?.minVersionAcceptedAppAndroid)) {
                message.error("Valore versione minima Android non valido")
                return
            }
            const properties = { ...formValue }

            const settings = await getSettings(token)
            {
                settings?.minVersionAcceptedApp === undefined
                    ? await createSettings(properties, token)
                    : await updateSettings(properties, token)
            }
            // setFormValue(settings)

            message.success("Elementi aggiornati con successo")
        } catch (error) {
            console.log(e)
            message.error("Errore durante l'aggiornamento degli elementi")
        }
    }

    return (
        <div className={`${className}`}>
            <h1 className="page-title">Impostazioni</h1>
            <div style={{ display: "flex" }}>
                Versione minima APP IOS
                <div style={{ marginLeft: 10, marginBottom: 30 }}>
                    <AppInput
                        value={formValue?.minVersionAcceptedApp}
                        onChange={(e) => onChange("minVersionAcceptedApp", e)}
                        className="search"
                        label="Versione minima APP"
                    ></AppInput>
                </div>
            </div>
            <div style={{ display: "flex" }}>
                Versione minima APP Android
                <div style={{ marginLeft: 10, marginBottom: 30 }}>
                    <AppInput
                        value={formValue?.minVersionAcceptedAppAndroid}
                        onChange={(e) =>
                            onChange("minVersionAcceptedAppAndroid", e)
                        }
                        className="search"
                        label="Versione minima APP Android"
                    ></AppInput>
                </div>
            </div>
            <div style={{ display: "flex" }}>
                Versione minima Kiosk
                <div style={{ marginLeft: 10, marginBottom: 30 }}>
                    <AppInput
                        value={formValue?.minVersionAcceptedKiosk}
                        onChange={(e) => onChange("minVersionAcceptedKiosk", e)}
                        className="search"
                        label="Versione minima KIOSK"
                    ></AppInput>
                </div>
            </div>
            Link aggiornamento ios
            <div style={{ marginLeft: 10, marginBottom: 30 }}>
                <AppInput
                    value={formValue?.iosUpdateLink}
                    onChange={(e) => onChange("iosUpdateLink", e)}
                    label="Link aggiornamento ios"
                ></AppInput>
            </div>
            Link aggiornamento android
            <div style={{ marginLeft: 10, marginBottom: 30 }}>
                <AppInput
                    value={formValue?.androidUpdateLink}
                    onChange={(e) => onChange("androidUpdateLink", e)}
                    label="Link aggiornamento android"
                ></AppInput>
            </div>
            Link aggiornamento Kiosk
            <div style={{ marginLeft: 10, marginBottom: 30 }}>
                <AppInput
                    value={formValue?.kioskUpdateLink}
                    onChange={(e) => onChange("kioskUpdateLink", e)}
                    label="Link aggiornamento kiosk"
                ></AppInput>
            </div>
            <div className="buttons-row">
                <AppButton onClick={saveSettings}>Salva</AppButton>
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Settings.propTypes = {
    className: PropTypes.string,
}

// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
const Settings = styled(_Settings)`
    & {
    }
`
export default Settings
