import { useEffect, useState } from "react"

import { getStoreGroups, getShops } from "../../../../api/magento"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import dayjs from "dayjs"
const format = "HH:mm"

/**
 * @typedef StoreGroup
 * @property {number} id
 * @property {string} code
 */

/**
 *
 * @returns {[StoreGroup[], boolean]}
 */
export function useShops() {
    const [stores, setStores] = useState([])

    const [loading, setLoading] = useState(true)
    const [{ token }] = useStateValue()

    useEffect(() => {
        setLoading(true)
        getStoreGroups(token)
            .then((response) => {
                if (response && response.length) {
                    setStores(
                        response.map((store) => {
                            return {
                                id: store.id,
                                code: store.code,
                            }
                        })
                    )
                }
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false)
            })
        //eslint-disable-next-line
    }, [])

    return [stores, loading]
}

export function useShops2() {
    const [stores, setStores] = useState([])
    const [loading, setLoading] = useState(true)
    const [{ token }] = useStateValue()

    useEffect(() => {
        setLoading(true)
        getShops(token)
            .then((response) => {
                if (response && response.length) {
                    setStores(
                        response.map((store) => {
                            return {
                                uid: store.storelocator_id,
                                code: store.storename,
                                monday: {
                                    morningStartTime: dayjs("08:00", format),
                                    morningStopTime: dayjs("21:30", format),

                                    afternoonStartTime: dayjs("16:00", format),
                                    afternoonStopTime: dayjs("22:00", format),
                                    continuedTime: true,
                                    isClosed: false,
                                },
                                tuesday: {
                                    morningStartTime: dayjs("08:00", format),
                                    morningStopTime: dayjs("21:30", format),

                                    afternoonStartTime: dayjs("16:00", format),
                                    afternoonStopTime: dayjs("22:00", format),
                                    continuedTime: true,
                                    isClosed: false,
                                },
                                wednesday: {
                                    morningStartTime: dayjs("08:00", format),
                                    morningStopTime: dayjs("21:30", format),

                                    afternoonStartTime: dayjs("16:00", format),
                                    afternoonStopTime: dayjs("22:00", format),
                                    continuedTime: true,
                                    isClosed: false,
                                },
                                thursday: {
                                    morningStartTime: dayjs("08:00", format),
                                    morningStopTime: dayjs("21:30", format),

                                    afternoonStartTime: dayjs("16:00", format),
                                    afternoonStopTime: dayjs("22:00", format),
                                    continuedTime: true,
                                    isClosed: false,
                                },
                                friday: {
                                    morningStartTime: dayjs("08:00", format),
                                    morningStopTime: dayjs("21:30", format),

                                    afternoonStartTime: dayjs("16:00", format),
                                    afternoonStopTime: dayjs("22:00", format),
                                    continuedTime: true,
                                    isClosed: false,
                                },
                                saturday: {
                                    morningStartTime: dayjs("08:00", format),
                                    morningStopTime: dayjs("21:30", format),

                                    afternoonStartTime: dayjs("16:00", format),
                                    afternoonStopTime: dayjs("22:00", format),
                                    continuedTime: true,
                                    isClosed: false,
                                },
                                sunday: {
                                    morningStartTime: dayjs("08:00", format),
                                    morningStopTime: dayjs("14:00", format),

                                    afternoonStartTime: dayjs("16:00", format),
                                    afternoonStopTime: dayjs("22:00", format),
                                    continuedTime: true,
                                    isClosed: false,
                                },
                            }
                        })
                    ).toArray()
                }
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false)
            })
        //eslint-disable-next-line
    }, [])

    return [stores, loading]
}
